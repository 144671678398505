
























































































import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Branding extends Vue {
  public dataBrandName: string = '';
  public dataBrandAboutEnglish: string = '';
  public dataBrandAboutFrench: string = '';
  public dataThemeName: string = '';
  public dataThemeColorPrimary: string = '';
  public dataThemeColorSecondary: string = '';
  public dataThemeColorAccent: string = '';
  public dataThemeColorError: string = '';
  public dataThemeColorInfo: string = '';
  public dataThemeColorSuccess: string = '';
  public dataThemeColorWarning: string = '';
  public data: any | null = null;
  public invalid: boolean = false;

  public async beforeMount(): Promise<void> {
    this.reset();

    const result = await Vue.axios.get('/dealers/getBranding');
    this.data = result.data;

    this.reset();
  }

  public url1(): string {
    return window.location.origin + '/d/' + this.$store.state.signedInUserName;
  }

  public url2(): string {
    return window.location.origin + '/d/' + window.btoa(this.$store.state.signedInUserName);
  }

  public async submit(): Promise<void> {
    await Vue.axios.post('/dealers/setBranding', {
      name: this.dataBrandName,
      aboutTextEnglish: this.dataBrandAboutEnglish,
      aboutTextFrench: this.dataBrandAboutFrench,
      themeName: this.dataThemeName,
      themeColors: {
        primary: this.dataThemeColorPrimary,
        secondary: this.dataThemeColorSecondary,
        accent: this.dataThemeColorAccent,
        error: this.dataThemeColorError,
        info: this.dataThemeColorInfo,
        success: this.dataThemeColorSuccess,
        warning: this.dataThemeColorWarning,
      },
    });

    window.location.reload();
  }

  public reset(): void {
    if (this.data === undefined || this.data === null || this.data === '') {
      this.dataBrandName = '';
      this.dataBrandAboutEnglish = '';
      this.dataBrandAboutFrench = '';
      this.dataThemeName = 'light';
      this.dataThemeColorPrimary = '#1976D2';
      this.dataThemeColorSecondary = '#424242';
      this.dataThemeColorAccent = '#82B1FF';
      this.dataThemeColorError = '#FF5252';
      this.dataThemeColorInfo = '#2196F3';
      this.dataThemeColorSuccess = '#4CAF50';
      this.dataThemeColorWarning = '#FFC107';
    } else {
      this.dataBrandName = this.data.name;
      this.dataBrandAboutEnglish = this.data.aboutTextEnglish;
      this.dataBrandAboutFrench = this.data.aboutTextFrench;
      this.dataThemeName = this.data.themeName;
      this.dataThemeColorPrimary = this.data.themeColors.primary;
      this.dataThemeColorSecondary = this.data.themeColors.secondary;
      this.dataThemeColorAccent = this.data.themeColors.accent;
      this.dataThemeColorError = this.data.themeColors.error;
      this.dataThemeColorInfo = this.data.themeColors.info;
      this.dataThemeColorSuccess = this.data.themeColors.success;
      this.dataThemeColorWarning = this.data.themeColors.warning;
    }
  }

}
