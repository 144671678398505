














































import { Component, Vue } from 'vue-property-decorator';
import * as Cookie from 'tiny-cookie';

@Component({})
export default class About extends Vue {
  
  public isBranded(): boolean {
    const brandName = Cookie.get('brand-name');
    if (brandName === null) return false;
    else return true;
  }

  public brandingName(): string {
    const brandName = Cookie.get('brand-name');
    if (brandName === null) return '';
    else return brandName;
  }

  public brandingAboutEnglish(): string {
    const text = Cookie.get('brand-aboutTextEnglish');
    if (text === null) return '';
    else return text.replace(/\n/g, '<br/>');
  }

  public brandingAboutFrench(): string {
    const text = Cookie.get('brand-aboutTextFrench');
    if (text === null) return '';
    else return text.replace(/\n/g, '<br/>');
  }

}
